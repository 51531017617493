<template>
    <div class="sidebar">
        <MenuButton @menu-button-clicked="menuButtonClicked"></MenuButton>
        <div class="checkbox-list">
            <Tabs @toggle-tab-button="toggleTabButton"/>
            <div class="checkbox">
                <label class="phfb">
                    <input
                        id="phfb"
                        type="checkbox"
                        v-model="phfb_checkbox"
                        @click="checkboxClicked($event)"
                    />
                    <span>PHFB</span>
                </label>
            </div>
            <div class="checkbox">
                <label class="ic-apex-checkbox">
                    <input
                        id="ic-apex"
                        type="checkbox"
                        v-model="icapex_checkbox"
                        @click="checkboxClicked($event)"
                    />
                    <span>iC-Apex</span>
                </label>
            </div>
            <div class="checkbox">
                <label class="ru-checkbox">
                    <input
                        id="ru"
                        type="checkbox"
                        v-model="ru_checkbox"
                    />
                    <span>Réserve utile (RU)</span>
                </label>
            </div>
            <div class="checkbox">
                <label class="bilan-hydrique-checkbox">
                    <input
                        id="bilan-hydrique"
                        type="checkbox"
                        v-model="bilan_hydrique_checkbox"
                    />
                    <span>Bilan hydrique</span>
                </label>
            </div>
        </div>
    </div>
</template>

<script>
import Tabs from '@/components/mapComponents/Tabs.vue'
import MenuButton from '@/components/animatedComponents/MenuButton.vue'

export default{
    
    data () {
        return {
            phfb_checkbox: false,
            icapex_checkbox: false,
            ru_checkbox: false,
            bilan_hydrique_checkbox: false,
        }
    },
    components: {
        Tabs,
        MenuButton,
    },
    methods:{
        // callback function to react to checkbox clicked
        checkboxClicked(e){
            // emit event with layers to display on map (according to checked boxes)
            this.$emit('checkbox-clicked', e);
        },
        toggleTabButton(){
            this.$emit('toggle-tab-button');
        },
        menuButtonClicked(){
            this.$emit('menu-button-clicked');
        }
    },
    created(){
        this.phfb_checkbox = (localStorage.getItem('display_phfb') === null || localStorage.getItem('display_phfb') === 'none' ) ? false : true;
        this.icapex_checkbox = (localStorage.getItem('display_ic_apex') === null || localStorage.getItem('display_ic_apex') === 'none' ) ? false : true;
    }

}
</script>

<style scoped>
    .sidebar {
        height: 94vh;
        width: 20vw;
        background-color: rgb(222,235,247);
        color: white;
        position: fixed;
        top: 6vh;
        bottom: 30px;
        left: 0;
        overflow-x: hidden;
        box-shadow: 5px 0px 5px rgba(0, 0, 0, 0.3); /* Offset-x, Offset-y, Blur-radius, Color */
    }
    .checkbox-list{
        position: relative;
        top: 10%;
        left: 5%;
        color: black;
        background-color: rgb(204, 226, 245);
        padding: 10px 0px;
        width: 18vw;
        display: flex;
        flex-direction: column; /* Display children vertically */
    }
    .checkbox{
        margin: 15px;
    }

    label:hover{
        color: rgb(41, 147, 239);
        cursor: pointer;
        font-weight: bold;
    }

    input:hover{
        cursor: pointer;
        border-radius: 10px;
        /* border-color: blue;
        accent-color: blue;
        border-width: 5px;
        color: blue; */
        transform:scale(1.1);
    }

    /* style of the text associated to the checkbox */
    input:checked + span {
        color: rgb(41, 147, 239);
        font-weight: bold;
    }

</style>