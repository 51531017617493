<template>
    <div class="home">
      <LeftSideBarConfig v-show="!leftSideBarMap" @close-config-side-bar="menuButtonClicked" @parcel-link-clicked="parcelLinkClicked" @spider-model-link-clicked="spiderModeLinkClicked"></LeftSideBarConfig>
      <SpiderModelComputing v-if="!showParcelsManagement" @generated-spider-coeffs="generatedSpiderCoeffs"></SpiderModelComputing>
      <ParcelsManagement v-if="showParcelsManagement"></ParcelsManagement>
    </div>
  </template>
  
  <script>
  // @: alias to '/src'
  import "leaflet/dist/leaflet.css";
  import LeftSideBarConfig from '@/components/configComponents/leftSideBarConfig.vue';
  import SpiderModelComputing from "@/components/configComponents/SpiderModelComputing.vue";
  import ParcelsManagement from "@/components/configComponents/ParcelsManagement.vue";

  export default {
    name: 'MapView',
    data () {
      return {
        coeffs: null,
        showParcelsManagement: null
      }
    },
    components: {
      LeftSideBarConfig,
      SpiderModelComputing,
      ParcelsManagement
    },
    computed: {
    },
    methods: {
      menuButtonClicked() {
        this.$router.push('/');
      },
      parcelLinkClicked(){
        this.showParcelsManagement = true;
        console.log('parcel');
      },
      spiderModeLinkClicked() {
        this.showParcelsManagement = false;
        console.log('spider');
      },
    },
    mounted (){
    },
    created () {
      this.showParcelsManagement = this.$route.query.showParcelsManagement == 'true';
    },
    // beforeRouteLeave() {
    //   // save spider coeffs
    //   // localStorage.setItem('spiderCoeffs', this.coeffs);
    // }
  }
  </script>