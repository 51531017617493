export const PHFB_ICON_PARAMS_SMALL = {
    iconUrl: 'images/phfb.svg', // Path relative to your public directory
    iconSize: [25, 40],
    iconAnchor: [12.5, 40],
    popupAnchor: [1, -34],
    className: 'phfb-icon'
}

export const ESTIMATED_PHFB_ICON_PARAMS_SMALL = {
    iconUrl: 'images/estimated_phfb.svg', // Path relative to your public directory
    iconSize: [25, 40],
    iconAnchor: [12.5, 40],
    popupAnchor: [1, -34],
    className: 'estimated-phfb-icon'
}

export const PHFB_ICON_PARAMS_BIG = {
    iconUrl: 'images/phfb_selected.svg',
    iconSize: [31, 50],
    iconAnchor: [15.5, 50],
    popupAnchor: [1, -34],
    className: 'phfb-icon'
}

export const ESTIMATED_PHFB_ICON_PARAMS_BIG = {
    iconUrl: 'images/estimated_phfb_selected.svg', 
    iconSize: [31, 50],
    iconAnchor: [15.5, 50],
    popupAnchor: [1, -34],
    className: 'estimated-phfb-icon'
}

export const IC_APEX_ICON_PARAMS_SMALL = {
    iconUrl: 'images/apex.svg', // Path relative to your public directory
    iconSize: [25, 40],
    iconAnchor: [12.5, 40],
    popupAnchor: [1, -34],
    className: 'ic-apex-icon'
}

export const IC_APEX_ICON_PARAMS_BIG = {
    iconUrl: 'images/apex_selected.svg', // Path relative to your public directory
    iconSize: [31, 50],
    iconAnchor: [15.5, 50],
    popupAnchor: [1, -34],
    className: 'ic-apex-icon'
}

