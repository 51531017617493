// const baseURL = `http://localhost:5000/`;
// const baseURL = `http://mapx-vigne.agrotic.org/python`;
const baseURL = `/python/`;

// returns a list of all parcel names
export const getParcelsPHFB = async () => {
    try{
        console.log("test");
        // fetch the data
        const res = await fetch(baseURL + `getParcelsPHFB`);
        if (!res.ok) {
          const message = 'Error';
          throw new Error(message);
        }
        
        const parcelNames = await res.json();

        return parcelNames;

        } catch(error) {
            console.error(error.message);
        }
};


// returns a list of all parcel names
export const isSeasonValidPHFBModel = async () => {
  try{
      console.log("test");
      // fetch the data
      const res = await fetch(baseURL + `getParcelNamesPHFB`);
      if (!res.ok) {
        const message = 'Error';
        throw new Error(message);
      }
      
      const parcelNames = await res.json();

      // make an array of Measures
      // let measures = [];
      // observations.forEach((obs) => {
      //     // formatting date to mm/dd/yyyy
      //     const dateParts = obs.date.split("/");
      //     const date =  new Date(dateParts[1] + "/" + dateParts[0] + "/" + dateParts[2]);
      //     measures.push(new Measure(parseInt(obs.id),obs.parcel_name, Number(obs.phfb_value.replace(",",".")), obs.measure_type, obs.type, date, obs.reliability, Number(obs.latitude.replace(",",".")), Number(obs.longitude.replace(",","."))));
      // });

      return parcelNames;

      } catch(error) {
          console.error(error.message);
      }
};