<template>
    <div class="overlay"> <!-- to put the rest of the page at the background -->
        <div class="loading-spinner"> <!-- container for the spinner -->
            <div class="spinner"></div> <!-- spinner itself -->
        </div>
    </div>
</template>

<style>
    .overlay{
        position: fixed;
        top: 6vh;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3); /* semi-transparent background */
        z-index: 1000;
    }
    .loading-spinner {
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        display: block;
        top: 45vh;
        left: 57vw;
    }
    .spinner {
        border: 7px dashed rgba(45, 163, 193, 1);
        border-top: 4pw solid #3498db;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        animation: spin 4s linear infinite;
    }
    @keyframes spin {
        0% {transform: rotate(0deg);}
        100% {transform: rotate(360deg);}
    }
</style>3