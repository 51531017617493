<template>
<transition name="slide">
    <div class="sidebar">
        <span class="close" @click="closeSideBar($event)">&times;</span>
        <MeasureDetails
            v-for="(measure, index) in measures_to_show"
                v-bind:measure_to_show="measure"
                v-bind:index="index"
                v-bind:key="measure.id.toString() + '-' + index.toString()"
        />
    </div>
</transition>
</template>

<script>
import MeasureDetails from '@/components/mapComponents/MeasureDetails.vue'
export default{
    name:'RightSideBar',
    data () {
        return {
        }
    },
    components: {
        MeasureDetails,
    },
    props: {
        // measures to show inside bar (can be of all type (phfb, ic-apex, ru...))
        measures_to_show: Array
    },
    methods: {
        // callback function cross clicked
        closeSideBar(e){
            this.$emit('close-side-bar');
        }
    },
}
</script>

<style scoped>
    .sidebar {
        height: 94vh;
        width: 20vw;
        background-color: rgb(222,235,247);
        color: white;
        position: fixed;
        top: 6vh;
        bottom: 30px;
        left: 80%;
        overflow-x: hidden;
        box-shadow: -15px 5px 15px rgba(0, 0, 0, 0.3); /* Offset-x, Offset-y, Blur-radius, Color */
    }
    /* .measure-details{
        position: relative;
        border:solid 2px rgba(255, 255, 255, 0.642);
        top: 5%;
        left: 3%;
        color: black;
        width: 18vw;
    } */ 
    .close {
        font-size: 45px;
        font-weight: 500;
        display: inline-block;
        position: relative;
        left: 90%;
        color:rgb(255, 255, 255);
    }
    .close:hover{
        cursor:pointer;
        color:rgb(151, 151, 151);
    }

    .slide-leave-active,
.slide-enter-active {
    transition: 1s;
}
.slide-enter {
    transform: translate(-100%, 0);
}

.slide-enter-to{
    transform: translate(0%, 0);
}
.slide-leave {
    transform: translate(-100%, 0);
}
.slide-leave-to {
    transform: translate(100%, 0);
}
</style>