<template>
        <div class="container" >
            <h2 class="title">{{this.selectedParcel}}</h2>
            <div class="scrollable-list">
                <ul>
                    <li v-for="parcel in parcels" :key="parcel" @click="parcelClicked" class="clickable-item">{{ parcel }}</li>
                </ul>
            </div>
            <button class="button" v-if="this.selectedParcel != null" @click="addParcelButtonClicked"> Ajouter une mesure </button>
            <form @submit="submitForm" class="form" v-if="addParcel">
                <div class="form-group">
                    <label for="name">PHFB </label>
                    <input type="text" id="phfb" v-model="formData.phfb" required>
                </div>
              
                <div class="form-group">
                    <label for="birthdate">Date </label>
                    <input type="date" id="birthdate" v-model="formData.date" required>
                </div>

                <div class="form-group">
                    <label for="latitude">Latitude </label>
                    <input type="text" id="latitude" v-model="formData.latitude" required pattern="-?\d{1,3}\.\d+">
                </div>

                <div class="form-group">
                    <label for="longitude">Longitude </label>
                    <input type="text" id="longitude" v-model="formData.longitude" required pattern="-?\d{1,3}\.\d+">
                </div>

                <button type="submit" @click="submitForm">Valider</button>
            </form>
            <div v-if="isDataLoading">
                <LoadingSpinner></LoadingSpinner>
            </div>
    </div>

</template>


<script>
import * as db_interaction from '@/services/db_interaction'
import LoadingSpinner from "@/components/animatedComponents/LoadingSpinner.vue"
import * as backend_services from "@/services/backend_services";

export default{
    data () {
        return {
            isDataLoading: false,
            parcels: null,
            selectedParcel: null,
            addParcel: false,
            formData:{
                phfb: null,
                date: null,
                latitude: null,
                longitude: null
            }
        }
    },
    components: {
        LoadingSpinner
    },
    methods:{
        // TODO: also get the parcel names involved in ic-apex measures in a second version
        // get all parcel names involved in ic-apex and phfb measures
        async getParcels(){
            this.isDataLoading = true;
            this.parcels = await backend_services.getParcelsPHFB();
            this.isDataLoading = false;
        },
        parcelClicked(e){
            this.selectedParcel = e.target.textContent;
            console.log('this.selectedParcel: ', this.selectedParcel);
        },
        addParcelButtonClicked(e){
            this.addParcel = true;
        },
        submitForm(e){
            e.preventDefault();
            console.log( this.formData.phfb);
            console.log( this.selectedParcel);
            console.log( this.formData.date);
            console.log( this.formData.latitude);
            console.log( this.formData.longitude);
            console.log('ici');
            db_interaction.addMeasureParcel('PHFB', this.formData.phfb, this.selectedParcel, this.formData.date, this.formData.latitude, this.formData.longitude);
        }
    },
    created(){
        this.getParcels();
    }

}
</script>

<style scoped>
    div.container {
        position: fixed;
        left: 20vw;
        top:6vh;
        width: 80vw;
        height: 94vh;
        background-color: #5c7778;
        z-index: 0;
    }
    .listContainer {
        position: absolute;
        display: flex;
        top: 5%;
        left: 5%;
    }
    .dropDownContainer{
        position: absolute;
        top: 10%;
        left: 5%;
    }
    .button{
        position: absolute;
        top: 20%;
        left: 5%;
    }
    li{
        list-style-type: none;
        margin: 0px 5px;
    }
    .scrollable-list {
        position: absolute;
        top: 10%;
        left: 5%;
        height: 200px; /* Set the desired height for your scrollable list */
        width: 20%;
        overflow-y: auto; /* Enable vertical scrolling */
        border: 1px solid #ccc; /* Add a border or customize styles as needed */
    }

    .clickable-item:hover {
        cursor: pointer; /* Change the cursor to a pointer when hovering over the items to indicate they are clickable */
        background-color: gray;
    }
    .title{
        position: absolute;
        top: 5%;
        left: 8%;
    }
    .button{
        position: absolute;
        top: 35%;
        left: 10%;
        width: 8%;
        height: 4%;
    }
    .button:hover{
        cursor: pointer;
    }

    .form{
        position: absolute;
        top: 45%;
        left: 10%;
        width: 10%;
    }
    .form-group{
        margin-bottom: 10px;
    }
    input {
        width: 100%; /* Use 100% width for the inputs to make them appear as a single column */
        padding: 0px; /* Adjust padding to control size */

    }
</style>