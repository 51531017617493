<template>
    <div class="timeArrows">
        <button class="arrow-button" @click="moveDateBackward">
            <span>&larr;</span>
        </button>
        <span v-if="day === true" class="arrowText">{{this.getDayFromDate(this.current_date)  + ' ' + this.current_date.toLocaleDateString("fr")}}</span>
        <span v-else class="arrowText">{{this.current_week}}</span>
        <button class="arrow-button" @click="moveDateForward">
            <span>&rarr;</span>
        </button>
    </div>
</template>

<script>
import * as date_functions from '@/services/date_functions'

    export default{
    name:'TimeArrows',
    props: {
        day: Boolean,
        current_date: Date,
    },
    data(){
      return {
        // current_date: new Date('24 June 2022'),
      }
    },
    computed:{
        // Return the current week in the format: "Semaine du lundi dd/mm/yyyy"
        current_week() {
            // get monday's of the same week date
            const monday_of_same_week = date_functions.getMondayDate(this.current_date);

            // format monday's date
            const month = monday_of_same_week.getMonth() >= 9 ? (monday_of_same_week.getMonth() + 1).toString() : '0' + (monday_of_same_week.getMonth() + 1).toString();
            const date = monday_of_same_week.getDate() >= 9 ? monday_of_same_week.getDate().toString() : '0' + monday_of_same_week.getDate().toString();
            const year = monday_of_same_week.getFullYear();
            return 'Semaine du ' + date + '/' + month + '/' + year;
        }
    },
    methods: {
        getDayFromDate(date) {
            const week_days = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
            return week_days[date.getDay()];
        },
        // shift date backward
        moveDateBackward(){
            let newDate;
            // one day backward
            if (this.day) {
                // this.current_date = new Date(this.current_date.setDate(this.current_date.getDate() - 1));
                newDate = new Date(this.current_date.setDate(this.current_date.getDate() - 1));
            } else {
                // one week backward
                // this.current_date = new Date(this.current_date.setDate(this.current_date.getDate() - 7));
                newDate = new Date(this.current_date.setDate(this.current_date.getDate() - 7));
            }
            // this.$emit('new-date', this.current_date);
            this.$emit('new-date', newDate);
        },
        // shift date forward
        moveDateForward(){
            let newDate;
            // one day forward
            if (this.day) {
                // this.current_date = new Date(this.current_date.setDate(this.current_date.getDate() + 1));
                newDate = new Date(this.current_date.setDate(this.current_date.getDate() + 1));
            } else {
                // one week forward
                // this.current_date = new Date(this.current_date.setDate(this.current_date.getDate() + 7));
                newDate = new Date(this.current_date.setDate(this.current_date.getDate() + 7));
            }
            // this.$emit('new-date', this.current_date);
            this.$emit('new-date', newDate);
        }
    },
}
</script>


<style scoped>
    .arrow-button {
    border: none;
    width: 30px;
    height: 30px;
    background-color: rgb(222,235,247);
    cursor: pointer;
    border-radius: 20%;
    box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.3); /* Offset-x, Offset-y, Blur-radius, Color */
    
    }

    .arrow-button:hover {
        background-color: rgb(214, 224, 232);;
    }
    .arrowText{
        margin: 0px 20px;
    }

    /* .timeArrows{
        position: fixed;
        height: 29px;
    } */
</style>