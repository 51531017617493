<template>
    <div class="container" ref="mapContainer">
        <div class="listContainer">
            <li v-for="(item, index) in validSeasons" :key="index" @change="checkboxListClicked">
                <input type="checkbox" :value="item"/>
                {{ item }}
            </li>
        </div>
        <div class="dropDownContainer">
            <p>Selectionner une parcelle</p>
            <select v-model="refParcel">
                <option value="" disabled selected>Selectioner une parcelle </option>
                <option v-for="parcel in candidateRefParcels" :value="parcel">{{ parcel }}</option>
            </select>
        </div>
        <div class="button">
            <button type="submit" @click="clickButton">Calculer modèle</button>
        </div>


    </div>
    <div v-if="isDataLoading">
      <LoadingSpinner></LoadingSpinner>
    </div>


</template>


<script>
import * as db_interaction from '@/services/db_interaction'
import LoadingSpinner from "@/components/animatedComponents/LoadingSpinner.vue"

export default{
    
    data () {
        return {
            isDataLoading: false,
            validSeasons: [],
            validParcels: [],
            candidateRefParcels: [],
            refParcel: null,
            chosenSeasons: [],
            coeffs: null
        }
    },
    components: {
        LoadingSpinner
    },
    methods:{
        async getSeasonsPHFB(){
            this.isDataLoading = true;
            // request seasons
            this.result = await db_interaction.getValidSeasonsSpiderModel(); // TODO: test and verify this function
            // await db_interaction.testingMethod();
            this.isDataLoading = false;
            this.validSeasons = this.result[0];
            this.validParcels = this.result[1];
        },
        checkboxListClicked(e) {
            console.log(e);
            if (e.target.nodeName == 'INPUT' && e.target.checked) {
                this.chosenSeasons.push(e.target.value);
            } 
            if (e.target.nodeName == 'INPUT' && !e.target.checked) {
                this.chosenSeasons = this.chosenSeasons.filter(season => season != e.target.value);
            }
            console.log('chosen seasons: ', this.chosenSeasons);
            this.computecCandidateReferenceParcels();
        },
        // compute the parcels that can be used as reference parcels for the chosen seasons
        computecCandidateReferenceParcels(){
            // reset candidate reference parcels list
            while(this.candidateRefParcels.length > 0) {
                this.candidateRefParcels.pop();
            }
            // iterate over chosen seasons
            this.chosenSeasons.forEach((season) => {
                // iterate over valid parcels of the current season
                this.validParcels[season].forEach((parcel) => {
                    // add parcel to candidate reference parcels if not done already
                    if (!this.candidateRefParcels.includes(parcel)) {
                        this.candidateRefParcels.push(parcel);
                    }
                });
            });
            console.log('candidate ref parcels: ', this.candidateRefParcels);
        },
        async clickButton(){
            this.isDataLoading = true;
            // take off reference parcel from candidate parcels
            const candidateReferenceParcels = this.candidateRefParcels.filter(parcel => parcel != this.refParcel);
            // compute spider model coefficients
            this.coeffs = await db_interaction.computeSpiderCoefficients(this.chosenSeasons, candidateReferenceParcels, this.refParcel);
            //this.$emit('generated-spider-coeffs', this.coeffs);
            this.isDataLoading = false;
        }
    },
    mounted(){
        this.getSeasonsPHFB();
    },
    // beforeRouteLeave(to, from, next) {
    //     console.log('LAAAAAAAAAAAAAAAAAAA');
    //     // memorise variables value
        
    //     console.log('before leave: ', this.coeffs);
    //     next();
    // }
}
</script>

<style scoped>
    div.container {
        position: fixed;
        left: 20vw;
        top:6vh;
        width: 80vw;
        height: 94vh;
        background-color: #5c7778;
        z-index: 0;
    }
    .listContainer {
        position: absolute;
        display: flex;
        top: 5%;
        left: 5%;
    }
    .dropDownContainer{
        position: absolute;
        top: 10%;
        left: 5%;
    }
    .button{
        position: absolute;
        top: 20%;
        left: 5%;
    }
    li{
        list-style-type: none;
        margin: 0px 5px;
    }
</style>