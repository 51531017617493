<template>
  <div class="home">
    <Map @marker-clicked="markerClicked" v-bind:phfb_markers="phfb_markers" v-bind:ic_apex_markers="ic_apex_markers"
    v-bind:display_phfb="display_phfb" v-bind:display_ic_apex="display_ic_apex" @map-clicked="mapClicked" v-bind:right_side_bar_cross_closing_click="right_side_bar_cross_closing_click"/>
    <LeftSideBar v-show="leftSideBarMap" @checkbox-clicked="checkboxLayerClicked" @toggle-tab-button="toggleTabButton" @menu-button-clicked="menuButtonClicked"/>
    <LeftSideBarConfig v-show="!leftSideBarMap"  @close-config-side-bar="menuButtonClicked"></LeftSideBarConfig>
    <RightSideBar v-show="show_right_side_bar" v-bind:measures_to_show="measures_to_show" @close-side-bar="closeRightSideBar"/> 
    <div class="timeArrows"> <TimeArrows v-bind:current_date="current_date" v-bind:day="dailyMeasuresDisplay" @new-date="updateDate"/> </div>
    <div v-if="isDataLoading">
      <LoadingSpinner></LoadingSpinner>
    </div>
  </div>
</template>

<script>
// @: alias to '/src'
import LeftSideBar from '@/components/mapComponents/LeftSidebar.vue'
import Map from '@/components/mapComponents/Map.vue'
import RightSideBar from '@/components/mapComponents/RightSideBar.vue'
import "leaflet/dist/leaflet.css";
import * as db_interaction from '@/services/db_interaction'
import Marker from '@/models/Marker';
import Tabs from '@/components/mapComponents/Tabs.vue'
import TimeArrows from '@/components/mapComponents/TimeArrows.vue';
import * as date_functions from '@/services/date_functions'
import LoadingSpinner from "@/components/animatedComponents/LoadingSpinner.vue"
import * as backend_services from "@/services/backend_services";
import LeftSideBarConfig from '@/components/configComponents/leftSideBarConfig.vue';

export default {
  name: 'MapView',
  data () {
    return {
      phfb_measures: [],//db_interaction.getPHFBMeasures(),
      ic_apex_measures: [],//db_interaction.getICAPEXMeasures(),
      show_right_side_bar: false,// used to show or hide right side bar 
      right_side_bar_cross_closing_click: 1,// variable used to propagate right_saide_bar closing event to child map component (the event is represented by the change of the variable value, toggling between -1 and 1)
      measures_to_show: [],// on rightSideBar (can be of any type)
      display_phfb: 'none',// to display or hide phfb layer on map
      display_ic_apex: 'none',// to display or hide ic-apex layer on map
      time_arrows_left:'25%',
      dailyMeasuresDisplay: false,
      current_date: new Date('28 June 2012'),//  24 June 2022
      isDataLoading: false,// to show or hide spinner
      leftSideBarMap: true, // to toggle between leftSideBarMap and leftSideBarConfig
      spiderCoeffs: null
    }
  },
  components: {
    LeftSideBar,
    RightSideBar,
    Map,
    Tabs,
    TimeArrows,
    LoadingSpinner,
    LeftSideBarConfig
  },
  computed: {
    // computed property containing the list of phfb markers calculated from the list of phfb measures
    phfb_markers(){
        let phfb_markers = [];
        for (let i = 0; i < this.phfb_measures.length; i++){
          phfb_markers.push(new Marker(this.phfb_measures[i].id, this.phfb_measures[i].latitude, this.phfb_measures[i].longitude, this.phfb_measures[i].measureType));
        }
        return phfb_markers;
    },
    // computed propertry containing the list of ic-apex markers calculated from the list of ic-apex measures
    ic_apex_markers(){
        let ic_apex_markers = [];
        for (let i = 0; i < this.ic_apex_measures.length; i++){
          ic_apex_markers.push(new Marker(this.ic_apex_measures[i].id, this.ic_apex_measures[i].latitude, this.ic_apex_measures[i].longitude, this.ic_apex_measures[i].measureType));
        }
        return ic_apex_markers;
    },
  },
  methods: {
    // callback function to react to marker clicked
    markerClicked(marker_type, marker_id, e) {
      // if shift key was not pressed -> reset measures to show (in rightSideBar) array
      if (!e.originalEvent.ctrlKey) {
        // reset array
        while (this.measures_to_show.length > 0) {
          this.measures_to_show.pop();
        }
      }
      // add corresponding measure to measures to show
      switch (marker_type){
        case 'phfb':
          this.measures_to_show.push(this.phfb_measures.find(measure => measure.id === marker_id));
        break;

        case 'ic-apex':
          this.measures_to_show.push(this.ic_apex_measures.find(measure => measure.id === marker_id));
        break;
      }
      // display right side bar
      this.show_right_side_bar = true;
    },

    // checkboxes clicked (checked/unchecked) -> update infos to show on map
    checkboxLayerClicked(e) {
      // check if checkbox checked or unchecked
      if (e.target.checked) {
        // check layer to show (checkbox id)
        switch(e.target.id) {
          case 'phfb':
            // display layer to show
            this.display_phfb = 'block';
          break;
          case 'ic-apex':
            // display layer to show
            this.display_ic_apex = 'block';
          break;
        }
      } else {
        // check layer to hide (checkbox id)
        switch(e.target.id) {
          case 'phfb':
            // hide layer 
            this.display_phfb = 'none';
          break;
          case 'ic-apex':
            // hide layer 
            this.display_ic_apex = 'none';
          break;
        }
      }
    },
    // toggle between daily and weekly measures display
    toggleTabButton(){
      this.dailyMeasuresDisplay = !this.dailyMeasuresDisplay;
      // update measures
      this.updateMeasures();
    },

    // callback function to react to rightSideBar's cross (x) clicked
    closeRightSideBar(e){
      // trigger right_side_bar closing event for Map child component
      this.right_side_bar_cross_closing_click = this.right_side_bar_cross_closing_click * (-1);
      // unshow right side bar
      this.show_right_side_bar = false;
      // reset array of measures to show 
      this.removeAllMeasuresToShow();
    },

    // callback function to react to map clicked on empty space (i.e not on a marker)
    mapClicked(){
      // unshow right side bar
      this.show_right_side_bar = false;
      // reset array of measures to show in right side bar
      this.removeAllMeasuresToShow();
    },

    // reset array all measures to show in rightSideBar
    removeAllMeasuresToShow() {
      while (this.measures_to_show.length > 0) {
          this.measures_to_show.pop();
      }
    },

    // update the current date used for visualisation
    updateDate(newDate) {
      // update date
      this.current_date = newDate;
      // update measures
      this.updateMeasures();
    },

    // update ic_apex_measures from DB according to currentDate and display mode (estimation or real-data)
    // estimation -> measures of the day ; real-data -> measures of the week
    async updateICApexMeasures(pStartDate, pEndDate) {
        // get measures between dates range
        this.ic_apex_measures = await db_interaction.getICAPEXMeasures(pStartDate, pEndDate);

        // this.ic_apex_measures.forEach((apex_measure) => {
        //     console.log('id: ', apex_measure['id'], ' - latitude: ', apex_measure['latitude'],
        //     ' - longitude: ', apex_measure['longitude'],
        //     ' - apex_value: ', apex_measure['value'], 
        //     ' - date: ', apex_measure['date'], 
        //     ' - parcel_name: ', apex_measure['parcelName']);
        //   });
        // console.log(this.ic_apex_measures.length);
    },
    // update phfb_measures from DB according to provided startDate and endDate 
    async updatePHFBMeasures(pStartDate, pEndDate) {
        // get measures between dates range
        this.phfb_measures = await db_interaction.getPHFBMeasures(pStartDate, pEndDate);
        console.log('this.phfb_measures: ', this.phfb_measures);
    },
    async updateMeasures(){
      this.isDataLoading = true;
      // compute date range
      const dateRange = date_functions.computeDateRange(this.current_date, this.dailyMeasuresDisplay);
      
      // request ic-apex DB within date range
      this.updateICApexMeasures(dateRange[0], dateRange[1]);
      // request phfb DB within date range
      this.updatePHFBMeasures(dateRange[0], dateRange[1]);
      // Pause execution to avoid immediate clicking on time arrow button (immediate clicking sometimes causes loading errors)
      await new Promise(resolve => setTimeout(resolve, 1250));
      this.isDataLoading = false;
    },
    // async getParcelNamesPHFB() {
    //   await backend_services.getParcelNamesPHFB();
    // },
    menuButtonClicked() {
      this.leftSideBarMap = ! this.leftSideBarMap;
    },
    // gets the memorized parameters in the local storage if any
    getMemoParameters() {
      this.current_date = (localStorage.getItem('current_date') == null ) ? this.current_date : new Date(localStorage.getItem('current_date'));
      this.display_phfb = localStorage.getItem('display_phfb') == null ? 'none' : localStorage.getItem('display_phfb');
      this.display_ic_apex = localStorage.getItem('display_ic_apex') == null ? 'none' : localStorage.getItem('display_ic_apex');
      if (localStorage.getItem('dailyMeasuresDisplay') == null){
        console.log('test: ', localStorage.getItem('dailyMeasuresDisplay'));
        this.dailyMeasuresDisplay = false;
      } else {
        if (localStorage.getItem('dailyMeasuresDisplay') === 'true') {
          this.dailyMeasuresDisplay = true;
        } else {
          this.dailyMeasuresDisplay = false;
        }
      }
    }
  },
  
  created () {
    // get memorised paramaters if any
    this.getMemoParameters();
    // update measures
    this.updateMeasures();
    //this.getParcelNamesPHFB();
  },
  beforeRouteLeave() {
    // clear history
    localStorage.clear();
    // memorise variables value
    localStorage.setItem('current_date', this.current_date);
    localStorage.setItem('display_phfb', this.display_phfb);
    localStorage.setItem('display_ic_apex', this.display_ic_apex);
    localStorage.setItem('dailyMeasuresDisplay', this.dailyMeasuresDisplay);
  }
}
</script>


<style scoped>
div{
  position:fixed;
}

.timeArrows{
  position: fixed;
    top: 7%;
    left: v-bind('time_arrows_left');
    background-color: rgb(239, 243, 248);
}
</style>