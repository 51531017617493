<template>
    <div class="tab">
        <!-- <button class="tablinks" :class="estimationScreen ? 'active': 'non-active'" @click="toggleButton">Estimation</button> -->
        <!-- <button class="tablinks" :class="!estimationScreen ? 'active': 'non-active'" @click="toggleButton">Données collectées</button> -->
        <button v-for="(button, index) in buttons" :key="index" :class=button.displayed+button.type @click="toggleButton">
            {{ button.text }}
        </button>
    </div>
</template>


<script>
    export default {
        data () {
            return {
                // estimationScreen: true,
                buttons:[] // [{text:'Données Collectées', displayed:'active', type:' collectedData'},
                        //  {text:'Estimation', displayed:'non-active', type:' estimation'}]
            }
    },
    methods: {
            toggleButton(e) {
                // only take into account click on non-active button
                if(e.target.classList[0] === 'non-active'){
                    // this.estimationScreen = !this.estimationScreen;
                    this.buttons.reverse();
                    this.buttons.at(0).displayed = 'active';
                    this.buttons.at(1).displayed = 'non-active';
                    this.$emit('toggle-tab-button');
                }
            },
            // display collected data 
            displayCollectedData(){
                this.buttons = [{text:'Données Collectées', displayed:'active', type:' collectedData'},
                         {text:'Estimation', displayed:'non-active', type:' estimation'}];
            },
            // display estimation data
            displayEstimationData(){
                this.buttons = [{text:'Estimation', displayed:'active', type:' estimation'},
                {text:'Données Collectées', displayed:'non-active', type:' collectedData'}];
            }
        },
        created() {
            // get memorised getMemoParameters if any
            if (localStorage.getItem('dailyMeasuresDisplay') == null){
                this.displayCollectedData();// display collected data by default
            } else {
                if (localStorage.getItem('dailyMeasuresDisplay') === 'true'){
                    this.displayEstimationData();
                } else{
                    this.displayCollectedData();
                }
            }
        }
    }
</script>


<style>
    .tab {
        background-color: rgb(204, 226, 245);
        position: relative;
        left: 0%;
        top: 10%;
        width: 20vh;
        height: 10%;
        z-index: 9999!important;
        margin: 0 !important;
    }

    /* Style the buttons that are used to open the tab content */
    .tab button {
        background-color: rgb(204, 226, 245);/* (184, 217, 246); */
        float: left;
        border: none;
        outline: none;
        cursor: pointer;
        padding: 0px;
        border-radius: 5%;
        margin: 1px;
        padding: 3px 0px;
        position: relative;
        left: 10vh;
    }

    /* Change background color of buttons on hover */
    .tab button:hover {
        background-color: #ddd;
    }

    /* Create an active/current tablink class */
    .tab button.active {
        background-color: rgb(188, 220, 249);
        width: 80%;
        cursor: auto;
        font-weight: bold;
        color: rgb(0, 0, 0);
        transform: scale(1.1);
    }

    .tab button.non-active{
        background-color: rgb(204, 226, 245);
        font-size: 0.7em;
        text-decoration: underline;
        color: rgb(96, 96, 96);
    }

    .tab button.non-active:hover{
        transform: scale(1.1);
        color: rgb(41, 147, 239);
        font-weight: bold;
    }

    button.estimation.non-active {
        width: 35%;
        left: 14.5vh;
    }

    button.collectedData.non-active {
        width: 60%;
        left: 12vh;
    }

    /* Style the tab content */
    .tabcontent {
        display: none;
        padding: 6px 12px;
        border: 1px solid #ccc;
        border-top: none;
    }
</style>