<template>
  <nav>
    <h1 class="title">MapX Vigne</h1>
  </nav>
  <router-view/>
</template>

<script>
import * as db_interaction from '@/services/db_interaction'
  export default {
    data(){
      return {
        data: null
      }
    },
    computed: {
      currentRouteName() {
        return this.$route.name;
      }
    },
    created() {
      // db_interaction.getICAPEXMeasures().then((data) => {
      //   //console.log(data);
      // });
  },
}
  
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.title {
  position: relative;
  left:2%;
  display: inline-block;
  font-style:bold;
  margin: 0;
}

nav {
  position: fixed;
  width: 100vw;
  height: 6vh;
  left: 0;
  top: 0.5%;
  font-family: Georgia, serif;
  color: rgb(80, 80, 80);
  display: flex; /* to align elts vertically */
  align-items: center;
}

img {
  display: block;
  height: 80px;
  width: 70px;
}
</style>
