import Measure from '@/models/Measure.js';

// const baseURL = `http://localhost:5000/`;
// const baseURL = `http://mapx-vigne.agrotic.org/python`;/home/agrotic/mapx-vigne.agrotic.org/python
const baseURL = `/python/`;

// export const getPHFBMeasuresTest = () => {
//     let measures = [];
    
//     // for testing
//     measures.push(new Measure(0,'Oliveraie', - Math.random(), 'estimation', 'PHFB', new Date(2022, 6, 24), 'high', 44.505, -0.09));
//     measures.push(new Measure(2,'Orangeraie', - Math.random(),'estimation', 'PHFB', new Date(2022, 6, 25), 'high', 44.50, -0.06));
//     measures.push(new Measure(3,'Palmeraie', - Math.random(),'mesure', 'PHFB', new Date(2022, 6, 26), 'low', 44.4, -0.012));
//     measures.push(new Measure(4,'Chategneraie', - Math.random(), 'mesure', 'PHFB', new Date(2022, 6, 24), 'medium', 44.6, -0.012));
//     measures.push(new Measure(5,'Roses', - Math.random(), 'estimation', 'PHFB', new Date(2022, 6, 25), 'medium', 44.55, -0.02));
//     measures.push(new Measure(6,'Cheneraie', - Math.random(), 'mesure', 'PHFB', new Date(2022, 6, 26), 'high', 44.58, -0.09));
    
//     return measures;
    
// };

// export const getICAPEXMeasuresTest = () => {
//     let measures = [];
    
//     // for testing
//     measures.push(new Measure(0,'Oliveraie', Math.random(), 'estimation', 'IC-APEX', new Date(2022, 6, 24), 'high', 44.5, -0.08));
//     measures.push(new Measure(2,'Oliveraie', Math.random(), 'mesure', 'IC-APEX', new Date(2022, 6, 25), 'medium', 44.4, -0.1));
//     measures.push(new Measure(3,'Roses', Math.random(), 'estimation', 'IC-APEX', new Date(2022, 6, 26), 'medium', 44.45, -0.02));
//     measures.push(new Measure(4,'Roses', Math.random(), 'mesure', 'IC-APEX', new Date(2022, 6, 24), 'low', 44.65, -0.2));
//     measures.push(new Measure(5,'Roses', Math.random(), 'mesure', 'IC-APEX', new Date(2022, 6, 25), 'low', 44.3, -0.3));
//     measures.push(new Measure(6,'Orangeraie', Math.random(), 'estimation', 'IC-APEX', new Date(2022, 6, 26), 'high', 44.35, -0.05));
    
//     return measures;
    
// };


export const getICAPEXMeasures = async (startDate, endDate) => {
    try{
        const queryParams = new URLSearchParams();
        queryParams.append('startDate', startDate);
        queryParams.append('endDate', endDate);

        // fetch the data
        //const res = await fetch('http://localhost:5000/get_data?${queryParams.toString()}');
        const res = await fetch(baseURL + `getICAPEXMeasures?startDate=${startDate.toString()}&endDate=${endDate.toString()}`);
        if (!res.ok) {
          const message = 'Error';
          throw new Error(message);
        }
        
        const observations = await res.json();

        // make an array of Measures
        let measures = [];
        observations.forEach((obs) => {
            measures.push(new Measure(obs.id,obs.parcel_name, obs.apex_value, obs.measure_type, obs.type, obs.date, obs.reliability, obs.latitude, obs.longitude));
        });

        return measures;

        } catch(error) {
            console.error(error.message);
        }
};


export const getPHFBMeasures = async (startDate, endDate) => {
    try{
        const queryParams = new URLSearchParams();
        queryParams.append('startDate', startDate);
        queryParams.append('endDate', endDate);

        // fetch the data
        const res = await fetch(baseURL + `getPHFBMeasures?startDate=${startDate.toString()}&endDate=${endDate.toString()}`);
        if (!res.ok) {
          const message = 'Error';
          throw new Error(message);
        }
        
        const observations = await res.json();

        // make an array of Measures
        let measures = [];
        observations.forEach((obs) => {
            // format date to mm/dd/yyyy
            const dateParts = obs.date.split("/");
            const date =  new Date(dateParts[1] + "/" + dateParts[0] + "/" + dateParts[2]);
            // add measure
            measures.push(new Measure(parseInt(obs.id),obs.parcel_name, Number(obs.phfb_value.replace(",",".")), obs.measure_type, obs.type, date, obs.reliability, Number(obs.latitude.replace(",",".")), Number(obs.longitude.replace(",","."))));
        });

        return measures;

        } catch(error) {
            console.error(error.message);
        }
};

export const testingMethod = async () => {
    try{
        // fetch the data
        const res = await fetch(baseURL + `testingMethod`);
        if (!res.ok) {
          const message = 'Error';
          throw new Error(message);
        }
    }
    catch(error) {
        console.error(error.message);
    }
};

export const getSpiderCoeffs = async () => {
    try{
        // fetch the data
        const res = await fetch(baseURL + `getCoeffs`);
        if (!res.ok) {
          const message = 'Error';
          throw new Error(message);
        }
        
        const coeffs = await res.json();

        return coeffs;

        } catch(error) {
            console.error(error.message);
        }
};

export const getValidSeasonsSpiderModel = async () => {
    try{
        // fetch the data
        const res = await fetch(baseURL + `getValidSeasonsSpiderModel`);
        if (!res.ok) {
          const message = 'Error';
          throw new Error(message);
        }
        
        const result = await res.json();

        return result;

        } catch(error) {
            console.error(error.message);
        }
};

export const computeSpiderCoefficients = async (seasons, candidateSatelliteParcels, refParcel) => {
    try{
        const queryParams = new URLSearchParams();
        queryParams.append('seasons', seasons);
        queryParams.append('candidateSatelliteParcels', candidateSatelliteParcels);
        queryParams.append('refParcel', refParcel);

        // fetch the data
        const res = await fetch(baseURL + `computeSpiderCoefficients?seasons=${seasons.toString()}&candidateSatelliteParcels=${candidateSatelliteParcels.toString()}&refParcel=${refParcel.toString()}`);
        if (!res.ok) {
          const message = 'Error';
          throw new Error(message);
        }
        
        const coeffs = await res.json();

        return coeffs;

        } catch(error) {
            console.error(error.message);
        }
};

// add a measure for the specified parcel in the DB
// measureType = {'PHFB', 'IC-APEX'}
// TODO: add another check to see if it's an IC-APEX measure, if yes call the function insertICAPEXMeasure (to define in app.py)
export const addMeasureParcel = async (measureType, value, parcel, date, latitude, longitude) => {
    try{
        const queryParams = new URLSearchParams();
        queryParams.append('value', value);
        queryParams.append('parcel', parcel);
        queryParams.append('date', date);
        queryParams.append('latitude', latitude);
        queryParams.append('longitude', longitude);
        let res;
        if (measureType === 'PHFB'){
            res = await fetch(baseURL + `insertPHFBMeasure?value=${value.toString()}&parcel=${parcel.toString()}&date=${date.toString()}&latitude=${latitude.toString()}&longitude=${longitude.toString()}`,{method: 'POST'});
        }
        if (!res.ok) {
          const message = 'Error';
          throw new Error(message);
        }

        } catch(error) {
            console.error(error.message);
        }
};