<template>
    <div class="mainDiv" @click="menuButton">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>

</template>

<script>
export default {
    methods: {
        menuButton() {
            this.$emit('menu-button-clicked');
        }
    },
}
</script>

<style scoped>
div.line {
  width: 30px;
  height: 4px;
  background-color: rgb(107, 102, 102);
  margin: 6px 6px;
  border-radius: 19%;
}

div.mainDiv:hover div {
    background-color: rgb(41, 147, 239);
    transform:scale(1.05);
}

div.mainDiv:hover {
    cursor: pointer;
}

div.mainDiv{
    display: inline-block;
}

</style>