// get monday's date of the same week
export const getMondayDate = (current_date) => {
    let current_day = current_date.getDay();
    current_day = current_day == 0 ? 7 : current_day;// if current_day is Sunday (Sunday is 0 in js Date)
    // number of days to monday
    const nb_days_to_monday = 1 - current_day;
    // date of the monday of the same week
    const monday_of_same_week = new Date(current_date);
    monday_of_same_week.setDate(monday_of_same_week.getDate() + nb_days_to_monday);
    
    return monday_of_same_week;
};

// get sunday's date of the same week
export const getSundayDate = (current_date) => {
    let current_day = current_date.getDay();
    current_day = current_day == 0 ? 7 : current_day;// if current_day is Sunday (Sunday is 0 in js Date)
    // number of days to sunday
    const nb_days_to_sunday = 7 - current_day;
    // date of the sunday of the same week
    const sunday_of_same_week = new Date(current_date);
    sunday_of_same_week.setDate(sunday_of_same_week.getDate() + nb_days_to_sunday);

    return sunday_of_same_week;
}

    // compute date range according to currentDate and display mode (estimation or real-data) 
    // estimation -> measures of the day ; real-data -> measures of the week
    // returns: array [startDate, endDate]
    export const computeDateRange = (current_date, dailyMeasuresDisplay) => {     
        let startDate = new Date(current_date);
        let endDate = new Date(current_date);
        
        // compute startDate and endDate
        if (dailyMeasuresDisplay) {// estimation-data display
          startDate = current_date;
          endDate = current_date;
        } else {// real-data display
          // get monday's date of the same week
          startDate = getMondayDate(current_date);
          // get sunday's date of the same week
          endDate = getSundayDate(current_date);
        } 
        
        // format start date (yyy-mm-dd 00:00:00)
        let month = startDate.getMonth() >= 9 ? (startDate.getMonth() + 1).toString() : '0' + (startDate.getMonth() + 1).toString();
        let date = startDate.getDate() >= 9 ? startDate.getDate().toString() : '0' + startDate.getDate().toString();
        let year = startDate.getFullYear();
        const pStartDate = year + '-' + month + '-' + date + ' ' + '00:00:00';
       
        // format end date (yyy-mm-dd 12:59:59)
        month = endDate.getMonth() >= 9 ? (endDate.getMonth() + 1).toString() : '0' + (endDate.getMonth() + 1).toString();
        date = endDate.getDate() >= 9 ? endDate.getDate().toString() : '0' + endDate.getDate().toString();
        year = endDate.getFullYear();
        const pEndDate = year + '-' + month + '-' + date + ' ' + '12:59:59';
        
        console.log('pStartDate ', pStartDate);
        console.log('pEndDate ', pEndDate);

        return [pStartDate, pEndDate];
    }