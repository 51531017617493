<template>
        <div class="map-container" ref="mapContainer"></div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import L from 'leaflet';
import {PHFB_ICON_PARAMS_SMALL, PHFB_ICON_PARAMS_BIG,ESTIMATED_PHFB_ICON_PARAMS_SMALL, ESTIMATED_PHFB_ICON_PARAMS_BIG, IC_APEX_ICON_PARAMS_SMALL, IC_APEX_ICON_PARAMS_BIG} from '@/constants/constants.js'

export default {
    name: 'Map',
    components: {
        
    },
    data() {
        return {
            center: [45.778787804984226, 3.085706680357553],// init map center
            phfbIcon: L.icon(PHFB_ICON_PARAMS_SMALL),
            phfbIconBig: L.icon(PHFB_ICON_PARAMS_BIG),
            estimatedPhfbIcon: L.icon(ESTIMATED_PHFB_ICON_PARAMS_SMALL),
            estimatedPhfbIconBig: L.icon(ESTIMATED_PHFB_ICON_PARAMS_BIG),
            icApexIcon: L.icon(IC_APEX_ICON_PARAMS_SMALL),
            icApexIconBig: L.icon(IC_APEX_ICON_PARAMS_BIG),
            mapMarkers: [],// displayable markers on the map ()
            map: null
        }
    },
    props: {
        phfb_markers: Array, // array of Markers (customised class)
        ic_apex_markers: Array, 
        display_phfb: String, // layers to show on map
        display_ic_apex: String,
        right_side_bar_cross_closing_click: Number
    },
    methods: {
        // set up map
        setUpMap() {
            // set map layer
            this.map = L.map(this.$refs.mapContainer,).setView(this.center, 6); // {zoomAnimation:false,} -> if needed: to avoid "Cannot read properties of null (reading '_latLngToNewLayerPoint')" error
            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {attribution: '© OpenStreetMap contributors',})
                .addTo(this.map);
            this.map.on('click', (e) => {
                this.$emit('map-clicked');
                this.unselectAllMarkers();
            });
        },
        // add markers to the map (markers are hiden by default)
        addMarkers() {
            // remove all existing markers from map
            this.removeMapMarkers();
            this.addPHFBMarkers();
            this.addICAPEXMarkers();
            
        },
        // add phfb markers to map
        addPHFBMarkers() {
            for (let i = 0; i < this.phfb_markers.length; i++){
                // add marker to map
                const map_marker = L.marker(L.latLng(parseFloat(this.phfb_markers[i].latitude), parseFloat(this.phfb_markers[i].longitude)), {icon: this.phfb_markers[i].measure_type == 'estimation' ? this.estimatedPhfbIcon : this.phfbIcon}).addTo(this.map);
                // set marker click listener
                map_marker.addEventListener('click', (e) => {
                    // if ctrl key wasn't pressed -> unselect all other markers
                    if (!e.originalEvent.ctrlKey) {
                        this.unselectAllMarkers();
                    }
                    // select marker clicked + send event
                    map_marker.setIcon(this.phfb_markers[i].measure_type == 'estimation' ? this.estimatedPhfbIconBig : this.phfbIconBig);
                    this.$emit('marker-clicked', 'phfb', this.phfb_markers[i].id, e);
                    this.tagMarkerAsClicked(map_marker._leaflet_id);
                });
                // set marker hover listener
                map_marker.addEventListener('mouseover', () => {
                    map_marker.setIcon(this.phfb_markers[i].measure_type == 'estimation' ? this.estimatedPhfbIconBig : this.phfbIconBig);
                });
                //set marker mouseout listener
                map_marker.addEventListener('mouseout', () => {
                    // check if marker is not already clicked
                    if (!this.isMarkerClicked(map_marker._leaflet_id)){
                        map_marker.setIcon(this.phfb_markers[i].measure_type == 'estimation' ? this.estimatedPhfbIcon : this.phfbIcon);
                    }
                });
                // register marker in mapMarker list
                this.mapMarkers.push([map_marker, 'not-clicked']);
            }
        },
        // add ic-apex markers to map
        addICAPEXMarkers() {
            for (let i = 0; i < this.ic_apex_markers.length; i++){
                // add marker to map
                //const map_marker = L.marker([this.ic_apex_markers[i].latitude, this.ic_apex_markers[i].longitude], {icon: this.icApexIcon}).addTo(this.map);
                const map_marker = L.marker(L.latLng(parseFloat(this.ic_apex_markers[i].latitude), parseFloat(this.ic_apex_markers[i].longitude)), {icon: this.icApexIcon}).addTo(this.map);
                // set marker click listener
                map_marker.addEventListener('click', (e) => {
                    // if ctrl key wasn't pressed -> unselect all other markers
                    if (!e.originalEvent.ctrlKey) {
                        this.unselectAllMarkers();
                    }
                    // select marker clicked + send event
                    map_marker.setIcon(this.icApexIconBig);
                    this.$emit('marker-clicked', 'ic-apex', this.ic_apex_markers[i].id, e);
                    this.tagMarkerAsClicked(map_marker._leaflet_id);
                });
                // set marker hover listener
                map_marker.addEventListener('mouseover', () => {
                    map_marker.setIcon(this.icApexIconBig);
                });
                // set marker mouseout listener
                map_marker.addEventListener('mouseout', () => {
                    // check if marker is not already clicked
                    if (!this.isMarkerClicked(map_marker._leaflet_id)){
                        map_marker.setIcon(this.icApexIcon);
                    }
                });
                // register marker in mapMarker list
                this.mapMarkers.push([map_marker, 'not-clicked']);
            }
        },
        // remove all markers from the map
        removeMapMarkers() {
            // remove markers from map
            this.mapMarkers.forEach((mapMarker) => {
                if (mapMarker) {
                    this.map.removeLayer(mapMarker[0]);// mapMarker is of the form [marker,'not-clicked']
                }
            });
            // reset markersMap list
            while (this.mapMarkers.length > 0){
                this.mapMarkers.pop();
            }
        },
        // tells if the marker of the specific ID is clicked (useful for check in mouseout event)
        isMarkerClicked(id) {
            // find marker with the matching id inside the mapMarkers list
            const marker = this.mapMarkers.find((mapMarker) => {
                return mapMarker[0]._leaflet_id == id;// mapMarker looks like: [marker, 'clicked'/'not-clicked']
            })
            // check if marker is clicked
            if (marker[1] === 'clicked') {
                return true;
            }
            return false;
        },
        // tags the marker of the specified ID as 'clicked'
        tagMarkerAsClicked(id) {
            // find marker with the matching id inside the mapMarkers list
            for (var i = 0; i < this.mapMarkers.length ; i++) {
                if (this.mapMarkers[i][0]._leaflet_id === id) {
                    // tag marker as clicked
                    this.mapMarkers[i][1] = 'clicked';
                }
            }
        },
        // tags all markers as 'not-clicked' + sets their icon's size back to normal size
        unselectAllMarkers() {
            for (var i = 0; i < this.mapMarkers.length ; i++) {
                // tag marker as not-clicked
                this.mapMarkers[i][1] = 'not-clicked';
                // get marker type
                const marker_type = this.mapMarkers[i][0]._icon.classList[1];
                // set its icon's size back to normal (according to its type)
                switch (marker_type) {
                    case 'phfb-icon':
                        this.mapMarkers[i][0].setIcon(this.phfbIcon);
                    break;
                    case 'estimated-phfb-icon':
                        this.mapMarkers[i][0].setIcon(this.estimatedPhfbIcon);
                    break;
                    case 'ic-apex-icon':
                        this.mapMarkers[i][0].setIcon(this.icApexIcon);
                    break;
                }                
            }
        },
    },
    watch: {
        // to listen to changes in ic_apex_markers changes
        ic_apex_markers() {
            //this.addICAPEXMarkers();
            this.addMarkers();
        },
        // whenever the value of this props changes the callback function is triggered
        // this allows to emit an event from the parent component (MapView) to here (Map child component) when the right_side_bar closing button is clicked
        right_side_bar_cross_closing_click(value) {
            this.unselectAllMarkers();
        }
    },
    mounted() {
        this.setUpMap();
        this.addMarkers();
    },
}
</script>

<style>
    div.map-container {
        position: fixed;
        left: 20vw;
        top:6vh;
        width: 80vw;
        height: 94vh;
        background-color: #5c7778;
        z-index: 0;
    }
    .phfb-icon{
        display: v-bind('display_phfb');
    }
    .estimated-phfb-icon{
        display: v-bind('display_phfb');
    }
    .ic-apex-icon{
        display: v-bind('display_ic_apex');
    }
</style>