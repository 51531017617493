<template>
        <div class="measure-details">
            <h3 style="text-align:center; margin:10px 0px">{{measure_to_show.parcelName}}</h3>
            <h5 style="text-align:center; margin:10px 0px">{{measure_to_show.type}}</h5>
            <p>{{ measureValueMessage }}</p>
            <div class="reliability">
                <p>Confiance</p>
                <img v-if="measure_to_show.reliability === 'high'" class="reliabilityImg" src="@/assets/high_reliability.png"/>
                <img v-else-if="measure_to_show.reliability === 'medium'" class="reliabilityImg" src="@/assets/medium_reliability.png"/>
                <img v-else="measure_to_show.reliability === 'low'" class="reliabilityImg" src="@/assets/low_reliability.png"/>
            </div>
            
            <div class="banner" :class="constraintLevel">
                <p v-if="constraintLevel === 'no-constraint'">Contrainte absente</p>
                <p v-else-if="constraintLevel === 'moderate-constraint'">Contrainte modérée</p>
                <p v-else="constraintLevel === 'high-constraint'">Contrainte forte</p>
            </div>
        </div>
</template>

<script>
    import Measure from '@/models/Measure.js';
    export default{
        name: 'MeasureDetails',
        props: {
            measure_to_show: Measure,
            index: Number,
            key: String,
        },
        computed: {
            // compute water constraint level
            constraintLevel(){
                switch(this.measure_to_show.type) {
                    case 'PHFB':
                        return this.constraintLevelFromPHFB(this.measure_to_show.value);

                    case 'IC-APEX':
                        return this.constraintLevelFromApex(Number(this.measure_to_show.value));
                }

            },
            // compute message to dispaly measure value according to its type
            measureValueMessage(){
                switch(this.measure_to_show.type) {
                    case 'PHFB':
                        return this.measure_to_show.value.toFixed(2).toString() + ' MPa' + ' (' + (this.measure_to_show.measureType == 'estimation' ? 'estimation' : 'mesure') + ')';
                    case 'IC-APEX':
                        return Number(this.measure_to_show.value).toFixed(2).toString() + ' (' + this.measure_to_show.measureType + ')';
                }
            }
        },
        methods:{
            // compute water level constraint according to a specified apex value
            constraintLevelFromApex(apex_value) {
                if (apex_value <= 0.33) {
                    return "high-constraint";
                } else {
                    if (apex_value <= 0.66 ) {
                        return "moderate-constraint";
                    } else {
                        return "no-constraint";
                    }
                }
            },
            // compute water level constraint according to a specified PHFB value 
            constraintLevelFromPHFB(phfb_value) {
                if (phfb_value > -0.33){
                    return "no-constraint";
                } else{
                    if (phfb_value <= -0.33 && phfb_value > -0.66){
                        return "moderate-constraint";
                    } else {
                        return "high-constraint";
                    }
                }
            }
        },
        mounted(){
        }
    }
</script>

<style>
    .measure-details{
        position: relative;
        top: 0%;
        left: 4%;
        color: black;
        width: 18vw;
        margin: 10px 0px;
        border-radius: 5px;
        background-color: rgb(189, 215, 238);
        padding: 2px;
    }

    p{
        position: relative;
        top: 5%;
        left: 3%;
        margin: 10px 0px;
    }

    .banner{
        position: relative;
        left: 3%;
        display: inline-block;
        padding-right: 8px;
        border-radius: 3px;
        margin-bottom: 4px;
    }

    .reliabilityImg{
        height: auto;
        width: 30%;
        margin-left: 20px;
    }

    .reliability{
        display: flex; /* to align elts vertically */
        align-items: center;
        margin-bottom: 3%;
    }

    .no-constraint{
        background-color: rgb(64, 172, 88, 0.5);
    }

    .moderate-constraint{
        background-color: rgb(255, 166, 0, 0.5);
    }

    .high-constraint{
        background-color: rgba(255, 67, 67, 0.5);
    }

    .banner p{
        display: inline-block;
        margin: 0;
    }
</style>

